body.pg-loading {
  overflow: hidden; }
  body.pg-loading #us_report_button {
    z-index: -10; }

.pg-loading-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000000;
  opacity: 1;
  background-color: #FFF;
  transition: background-color 0.4s ease-in-out 0s; }
  .pg-loading-screen.pg-loaded {
    opacity: 0;
    animation: pgAnimLoaded 0.5s cubic-bezier(0.7, 0, 0.3, 1) both; }
  .pg-loading-screen.pg-loading .pg-loading-logo-header, .pg-loading-screen.pg-loading .pg-loading-html {
    opacity: 1; }
  .pg-loading-screen.pg-loading .pg-loading-logo-header, .pg-loading-screen.pg-loading .pg-loading-html:not(.pg-loaded) {
    animation: pgAnimLoading 1s cubic-bezier(0.7, 0, 0.3, 1) both; }
  .pg-loading-screen.pg-loading .pg-loading-html:not(.pg-loaded) {
    animation-delay: 0.3s; }
  .pg-loading-screen .pg-loading-inner {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: static; }
  .pg-loading-screen .pg-loading-center-outer {
    width: 100%;
    padding: 0;
    display: table !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
  .pg-loading-screen .pg-loading-center-middle {
    padding: 0;
    vertical-align: middle;
    display: table-cell !important;
    margin: 0;
    text-align: center; }
  .pg-loading-screen .pg-loading-logo-header, .pg-loading-screen .pg-loading-html {
    width: 100%;
    opacity: 0; }
  .pg-loading-screen .pg-loading-logo-header {
    text-align: center; }
    .pg-loading-screen .pg-loading-logo-header img {
      display: inline-block !important;
      width: 300px; }
  .pg-loading-screen .pg-loading-html {
    margin-top: 50px; }
    .pg-loading-screen .pg-loading-html.pg-loaded {
      transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1); }
      .pg-loading-screen .pg-loading-html.pg-loaded.pg-removing {
        opacity: 0; }
      .pg-loading-screen .pg-loading-html.pg-loaded.pg-loading {
        opacity: 1; }
  .pg-loading-screen .loading-message {
    color: #fff; }
  .pg-loading-screen .spinner {
    margin: 50px auto 0;
    width: 70px;
    text-align: center; }
    .pg-loading-screen .spinner > div {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 100%;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
    .pg-loading-screen .spinner .bounce1 {
      animation-delay: -0.32s; }
    .pg-loading-screen .spinner .bounce2 {
      animation-delay: -0.16s; }

@keyframes pgAnimLoading {
  from {
    opacity: 0; } }

@keyframes pgAnimLoaded {
  from {
    opacity: 1; } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }
